import React from "react";
import {ARTICLE_12} from "shared/Types/articles";
import {useArticlesData} from "shared/Hooks/Articles/useArticlesData";
import SEO from "components/seo";

import {Author, Hero, Image, Layout, List, Text} from "components/BlogLayout";
import {TitleXL} from "../../components/Typography";
import {Container} from "../../components/BlogLayout/Text/styles";

const Article_12 = () => {
  const data = useArticlesData(ARTICLE_12);
  return (
    <Layout>
      <SEO title={data.SEO_title} description={data.seo} image={data.image}/>
      <Hero data={data}/>
      <List data={data.list.first}/>
      <Text data={data.text.first}/>
      <Text data={data.text.second}/>
      <Text data={data.text.third}/>
      <Text data={data.text.fourth}/>
      <Text data={data.text.fifth}/>
      <Image data={data.images.first}/>
      <Text data={data.text.sixth}/>
      <Text data={data.text.seventh}/>
      <Text data={data.text.eighth}/>
      <Text data={data.text.ninth}/>
      <Image data={data.images.second}/>
      <Text data={data.text.tenth}/>
      <Image data={data.images.third}/>
      <Text data={data.text.eleventh}/>
      <Text data={data.text.twelfth}/>
      <Image data={data.images.fourth}/>
      <Text data={data.text.thirteenth}/>
      <Text data={data.text.fourteenth}/>
      <Text data={data.text.fifteenth}/>
      <Image data={data.images.fifth}/>
      <Text data={data.text.sixteenth}/>
      <Text data={data.text.seventeenth}/>
      <Image data={data.images.sixth}/>
      <Text data={data.text.eighteenth}/>
      <Text data={data.text.nineteenth}/>
      <Image data={data.images.seventh}/>
      <Text data={data.text.twentyth}/>
      <Text data={data.text.twentyfirst}/>
      <Image data={data.images.eight}/>
      <Image data={data.images.ninth}/>
      <Text data={data.text.twentysecond}/>
      <Text data={data.text.twentythird}/>
      <Image data={data.images.tenth}/>
      <Text data={data.text.twentyfourth}/>
      <Image data={data.images.eleventh}/>
      <Text data={data.text.twentyfifth}/>
      <br/>
      <br/>
      <Container>
        <TitleXL>
          {data.ending}
        </TitleXL>
      </Container>
      <Author data={data.authors}/>
    </Layout>
  );
};

export default Article_12;
